import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"userTableTitle"},[_c('div',{staticStyle:{"font-weight":"400"}},[_vm._v("Удержания")]),_c(VSpacer),(_vm.currentUser.role === 'admin')?_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.createDeductions()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),_c(VDivider),(_vm.deductions.length === 0)?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет удержаний ")]):_vm._e(),(_vm.deductions && _vm.deductions.length > 0)?_c(VSimpleTable,{attrs:{"dense":!_vm.$isMobile},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дата")]),_c('th',{staticClass:"text-left"},[_vm._v("Сумма")]),_c('th',{staticClass:"text-center"},[_vm._v("Коммент.")]),(_vm.currentUser.role === 'admin')?_c('th',{staticClass:"text-center"},[_vm._v("Действия")]):_vm._e()])]),_c('tbody',_vm._l((_vm.deductions),function(deduction){return _c('tr',{key:deduction.id,staticClass:"tableItemList"},[_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v(_vm._s(_vm.$formatDate(new Date(deduction.date))))]),_c('td',[_vm._v(_vm._s(deduction.cost)+"р.")]),_c('td',{staticClass:"text-center"},[(deduction.comment)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment-text-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(deduction.comment))])]):_c('span',[_vm._v("Нет")])],1),(_vm.currentUser.role === 'admin')?_c('td',{staticClass:"text-center"},[_c(VBtn,{attrs:{"icon":"","color":"#bf4037"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(deduction)}}},[_c(VIcon,[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()])}),0)]},proxy:true}],null,false,2122153027)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }