<template>
  <v-card>
    <v-card-title class="userTableTitle">
      <div style="font-weight: 400;">Премии</div>
      <v-spacer></v-spacer>
      <v-btn v-if="currentUser.role === 'admin'" small icon @click="createPremiums()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <div v-if="premiums.length === 0" class="infoNoItems">
      Нет премий
    </div>
    <v-simple-table :dense="!$isMobile" v-if="premiums && premiums.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Дата</th>
            <th class="text-left">Сумма</th>
            <th class="text-center">Коммент.</th>
            <th v-if="currentUser.role === 'admin'" class="text-center">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="premium in premiums"
            :key="premium.id"
            class="tableItemList"
          >
            <td style="min-width: 80px;">{{ $formatDate(new Date(premium.date)) }}</td>
            <td>{{ premium.cost }}р.</td>
            <td class="text-center">
              <v-tooltip v-if="premium.comment" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-comment-text-outline
                  </v-icon>
                </template>
                <span>{{ premium.comment }}</span>
              </v-tooltip>
              <span v-else>Нет</span>
            </td>
            <td v-if="currentUser.role === 'admin'" class="text-center">
              <v-btn @click.stop="deleteItem(premium)" icon color="#bf4037">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { usersCollection } from '@/plugins/firebase'
import FinanceComment from '@/components/Modals/FinanceComment.vue'
import Confirm from  '@/components/Modals/Confirm'

export default {
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  computed: {
    premiums() {
      return this.$store.getters['premiums/getPremiums']
      // return this.user.premiums || []
    }
  },
  methods: {
    async deleteItem(data) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление премии`,
          text: `Действительно хотите удалить премию за ${this.$formatDate(new Date(data.date))}?`
        }
      })

      if (res) {
        await this.$store.dispatch('premiums/deletePremiums', data)
      }
    }, 
    async createPremiums() {
      let finObj = await this.$showModal({
        component: FinanceComment,
        isPersistent: false,
        props: {
          title: 'Новая премия',
          event: 'Выписать'
        }
      })

      if (finObj) {
        this.$store.dispatch('premiums/createPremiums', {
          cost: Number(finObj.summ),
          comment: finObj.comment,
          // user: usersCollection.doc(this.user.uid)
          user_id: this.user.id
        })
      }
    }
  }
}
</script>