import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[(_vm.title)?_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c(VCardText,{staticClass:"pb-0"},[_c(VTextField,{attrs:{"color":"#BF4037","type":"number","suffix":"р.","label":"Укажите сумму"},model:{value:(_vm.summ),callback:function ($$v) {_vm.summ=$$v},expression:"summ"}}),_c(VTextField,{attrs:{"color":"#BF4037","type":"text","label":"Комментарий"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Отменить ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.ok()}}},[_vm._v(" "+_vm._s(_vm.event)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }