import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"userTableTitle"},[_c('div',{staticStyle:{"font-weight":"400"}},[_vm._v("Выплаты")]),_c(VSpacer),(_vm.currentUser.role === 'admin')?_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.createPrepayment()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),_c(VDivider),(_vm.prepayments.length === 0)?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет выплат ")]):_vm._e(),(_vm.prepayments && _vm.prepayments.length > 0)?_c(VSimpleTable,{attrs:{"dense":!_vm.$isMobile},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дата")]),_c('th',{staticClass:"text-left"},[_vm._v("Сумма")]),(_vm.currentUser.role === 'admin')?_c('th',{staticClass:"text-left"}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('th',{staticClass:"text-center"},[_vm._v("Действия")]):_vm._e()])]),_c('tbody',_vm._l((_vm.prepayments),function(prepayment){return _c('tr',{key:prepayment.id,staticClass:"tableItemList"},[_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v(_vm._s(_vm.$formatDate(new Date(prepayment.date))))]),_c('td',[_vm._v(_vm._s(prepayment.cost)+"р.")]),(_vm.currentUser.role === 'admin')?_c('td',{staticStyle:{"min-width":"80px"}}):_vm._e(),(_vm.currentUser.role === 'admin')?_c('td',{staticClass:"text-center"},[_c(VBtn,{attrs:{"icon":"","color":"#bf4037"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(prepayment)}}},[_c(VIcon,[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()])}),0)]},proxy:true}],null,false,3490669216)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }