import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"userTableTitle"},[_c('div',{staticStyle:{"font-weight":"400"}},[_vm._v(" Заказы "),(_vm.costOrders)?_c('span',[_vm._v(_vm._s(_vm.costOrders))]):_vm._e()]),_c(VSpacer),(_vm.options && _vm.pagination)?_c(VDataFooter,{staticClass:"rl-data-footer",staticStyle:{"width":"310px","padding":"0"},attrs:{"options":_vm.options,"pagination":_vm.pagination,"items-per-page-text":"","items-per-page-options":[5, 10, 15, 20, 30, 40]},on:{"update:options":function($event){_vm.options=$event}}}):_vm._e()],1),_c(VDivider),(!_vm.orders || _vm.orders.length === 0)?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет заказов ")]):_vm._e(),(_vm.orders && _vm.orders.length > 0)?_c(VDataTable,{attrs:{"headers":_vm.tableHeadres,"items":_vm.orders,"items-per-page":10,"dense":!_vm.$isMobile,"options":_vm.options,"hide-default-footer":""},on:{"click:row":function($event){return _vm.editOrder($event)},"update:options":function($event){_vm.options = $event},"pagination":function($event){_vm.pagination = $event}},scopedSlots:_vm._u([{key:`item.archiveDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.archive_date ? (_vm.$formatDate(new Date(item.archive_date))): '-')+" ")]}},{key:`item.client`,fn:function({ item }){return [(item.client)?_c('span',[_vm._v(_vm._s(item.client.name))]):_c('span',[_vm._v("Не указан")])]}},{key:`item.cost`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.fullCost(item))+"р. ")]}},{key:`item.percent`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.percentOfOrder || 0)+"% ")]}},{key:`item.amount-work`,fn:function({ item }){return [_vm._v(" "+_vm._s((_vm.fullCost(item) * item.percentOfOrder || 0) / 100)+"р ")]}}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }