<template>
  <div>
    <Mob v-if="isMobile" />
   
    <Pc
      v-else
      :user="user"
      :currentUser="currentUser"
    />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { RoleText } from '@/helpers'
import Finance from '@/components/Modals/Finance.vue'
import SelectMonth from '@/components/Modals/SelectMonth'

const Mob = () => import('./Mob.vue')
const Pc = () => import('./Pc.vue')

export default {
  name: 'IndexUserHeader',
  components: {
    Mob,
    Pc
  },
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    isMobile: isMobile,
    roleText: RoleText
  }),
  created() {},
  computed: {
    filterUserDataByMonthText() {
      let month = this.filterUserDataByMonth
      // console.log('month',String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'})))
      // console.log('month',this.filterUserDataByMonth)
      return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
    },
    filterUserDataByMonth: {
      get() {
        return this.$store.getters['users/getFilterUserDataByMonth']
      },
      set(val) {
        this.$store.commit('users/setFilterUserDataByMonth', val)
      }
    },
    leftToPaid() {
      let earned = this.earned

      let leftToPaid = 0

      let paid = 0
      let prepayments = this.prepayments

      if (prepayments && prepayments.length > 0) {
        prepayments.forEach(prepayment => {
          paid = Number(paid) + Number(prepayment.cost)
        })
      }

      let deductionsSumm = 0
      let deductions = this.deductions

      if (deductions && deductions.length > 0) {
        deductions.forEach(deduction => {
          deductionsSumm = Number(deductionsSumm) + Number(deduction.cost)
        })
      }

      leftToPaid = earned - paid - deductionsSumm

      return leftToPaid
    },
    earned() {
      if(!this.user.id) return 0
      let earned = 0

      let premiums = this.premiums
      if (premiums && premiums.length > 0) {
        premiums.forEach(premiums => {
          earned = Number(earned) + Number(premiums.cost)
        })
      }

      
      
     
      if (this.montages && this.montages.length > 0) {
        this.montages.forEach(montage => {
          if (montage.montagersInfo) {
            if (montage.montagersInfo[this.user.id]) {
              if (montage.montagersInfo[this.user.id].costMontage) {
                earned = Number(earned) +  Number(montage.montagersInfo[this.user.id].costMontage)
              }
            }
          }
        })
      }

      let tasks = this.tasks
      if (tasks && tasks.length > 0) {
        tasks.forEach(task => {
          if (task.montagersInfo[this.user.id] && task.montagersInfo[this.user.id].costWork) {
            earned = Number(earned) + Number(task.montagersInfo[this.user.id].costWork)
          }
        })
      }
			
			let orders = this.orders

      if (orders && orders.length > 0) {
        let fullOrdersPrice = 0
        orders.forEach(order => {
					// fullCost
          let fullCost = this.fullCost(order)
					let percent = order.percentOfOrder || 0
         fullOrdersPrice = fullOrdersPrice +  Number((fullCost * percent) / 100)

        })

        earned = Number(earned) + Number(fullOrdersPrice)
      }

      let hourlyWorks = this.hourlyWorks
      if (hourlyWorks && hourlyWorks.length > 0) {
        hourlyWorks.forEach(work => {
          if (work.hourPayWorkMasters) {
            let ifHPWM = this.$ifHourPayWorkMasters(work, this.user)
            earned = Number(earned) + ifHPWM.cost
          } else if (work.numberOfHours) {
            let rate = 0
            if (work.rate) {
              rate = work.rate
            }
            earned = Number(earned) + (Number(work.numberOfHours) * rate)
          }
        })
      }

      return earned
    },
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks']
      // return this.currentUser.role != 'admin' ? this.currentUser.hourly_works || [] : this.user.hourly_works || []
    },
    montages() {
      return this.$store.getters['montages/getMontagesForMontager']
      // return this.currentUser.role != 'admin' ? this.currentUser.montages || [] : this.user.montages || []
    },
    tasks() {
      return this.$store.getters['manufactory/getTasksForMaster']
      // return this.currentUser.role != 'admin' ? this.currentUser.manufactory || [] : this.user.manufactory || []
    },
		orders() {
      return this.$store.getters['orders/getOrdersForManager']
      // return this.user.orders
    },
    prepayments() {
      return this.$store.getters['prepayments/getPrepayments']
      // return this.user.prepayments
    },
    deductions() {
      return this.$store.getters['deductions/getDeductions']
      // return this.user.prepayments
    },
    premiums() {
      return this.$store.getters['premiums/getPremiums']
      // return this.user.premiums
    }
  },
  methods: {
		fullCost(order) {
			let summ = 0
			order.paid.forEach(paid => {
				summ = summ + Number(paid.amount)
			});
			return summ
		},
    async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterUserDataByMonth
        }
      })
      if (modalMonth) {
        this.filterUserDataByMonth = modalMonth
      }
    },
    async credit(event) {
      let texts = {
        writeOff: 'Списать',
        giveOut: 'Выдать'
      }
      let summ = await this.$showModal({
        component: Finance,
        isPersistent: false,
        props: {
          title: `${texts[event]} кредит`,
          event: texts[event]
        }
      })

      let credit = this.user.credit ? this.user.credit : 0

      if (event === 'writeOff') {
        credit = Number(credit) - Number(summ)
      } else if (event === 'giveOut') {
        credit = Number(credit) + Number(summ)
      }

      this.$store.dispatch('users/updateUser', { id: this.user.id, data: { credit: credit } })

      if (event === 'writeOff') {
        this.$store.dispatch('deductions/createDeductions', {
          cost: Number(summ),
          comment: 'Кредит',
          user_id: this.user.id
          // user: usersCollection.doc(this.user.id)
        })
      }
    }
  }
}
</script>
