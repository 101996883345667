<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>

        <v-card-text class="pb-0">
          <v-text-field
            v-model="summ"
            color="#BF4037"
            type="number"
            suffix="р."
            label="Укажите сумму"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="close()"
          >
            Отменить
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="ok()"
          >
            {{ event }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: false
    },
    event: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialog: true,
    summ: null,
    comment: null
  }),
  methods: {
    ok() {
      this.$emit('close', this.summ)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
